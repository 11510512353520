@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url(./Fonts/Poppins/Poppins-Light.otf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "Gilroy";
  src:
    local("Gilroy"),
    url(./Fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "Riviera";
  src:
    local("Riviera"),
    url(./Fonts/Riviera/Riviera.ttf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "Pixellari";
  src:
    local("Pixellari"),
    url(./Fonts/Pixellari/Pixellari.ttf) format("truetype");
  font-display: fallback;
}

:root {
  --bg-header-color: rgba(4, 21, 43, 0.6);
  --bg-color: #04152b;
  --bgTwo-color: #022a43;
  --bgThree-color: transparent;
  --body-color: #ffffff;
  --body2-color: #c2c2c2;
  --bodyInvert-color: #1e1e1e;
  --title-color: #78d7dd;
  --subtitle-color: #78d7dd;
  --subtitleInvert-color: #78d7dd;
  --blueCyan-color: #fb4661;
  --bg-project: #3b404a;
  --red-color: #fb4661;
  --cursor-color: rgba(251, 70, 97, 0.5);
  --gradient: linear-gradient(
    0deg,
    rgba(120, 215, 221, 1) 0%,
    rgba(249, 78, 78, 1) 100%
  );
  --gradientS: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientBI: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientR: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--red-color));
  --gradientRI: linear-gradient(to top, rgba(0, 0, 0, 0), var(--red-color));
  --gradientLine: linear-gradient(
    0deg,
    rgba(120, 215, 221, 0) 0%,
    rgba(249, 78, 78, 1) 10%,
    rgba(249, 78, 78, 1) 50%,
    rgba(249, 78, 78, 1) 98%,
    rgba(249, 78, 78, 0) 100%
  );
  --loading-color: #78d7dd;
  --font-title: "Pixellari";
  --font-title2: "Riviera";
  --font-subtitle: "Gilroy";
  --font: "Poppins";
}

.hacker {
  --bg-header-color: rgba(0, 0, 0, 0.6);
  --bg-color: #000000;
  --bgTwo-color: #202020;
  --bgThree-color: transparent;
  --body-color: #e9e9e9;
  --body2-color: #c2c2c2;
  --bodyInvert-color: #1e1e1e;
  --title-color: #00b007;
  --subtitle-color: #00b007;
  --subtitleInvert-color: #00b007;
  --blueCyan-color: #ff0000;
  --bg-project: #422121;
  --red-color: #ff0000;
  --cursor-color: rgba(255, 0, 0, 0.5);
  --gradient: linear-gradient(
    0deg,
    rgba(0, 176, 7, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --gradientS: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientBI: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientR: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--red-color));
  --gradientRI: linear-gradient(to top, rgba(0, 0, 0, 0), var(--red-color));
  --gradientLine: linear-gradient(
    0deg,
    rgba(120, 215, 221, 0) 0%,
    rgba(255, 0, 0, 1) 10%,
    rgba(255, 0, 0, 1) 50%,
    rgba(255, 0, 0, 1) 98%,
    rgba(249, 78, 78, 0) 100%
  );
  --loading-color: #ff0000;
  --font-title: "Pixellari";
  --font-title2: "Riviera";
  --font-subtitle: "Gilroy";
  --font: "Poppins";
}

.cyber {
  --bg-header-color: rgba(10, 3, 32, 0.6);
  --bg-color: #14031f;
  --bgTwo-color: #130643;
  --bgThree-color: transparent;
  --body-color: #d0d5f2;
  --body2-color: #c2c2c2;
  --bodyInvert-color: #1e1e1e;
  --title-color: #faff00;
  --subtitle-color: #faff00;
  --subtitleInvert-color: #faff00;
  --blueCyan-color: #b629f2;
  --bg-project: #351d1d;
  --red-color: #b629f2;
  --blue-color: #00f0ff;
  --cursor-color: rgba(182, 41, 242, 0.5);
  --gradient: linear-gradient(
    0deg,
    rgba(182, 41, 242, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --gradientS: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientBI: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientR: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--red-color));
  --gradientRI: linear-gradient(to top, rgba(0, 0, 0, 0), var(--red-color));
  --gradientLine: linear-gradient(
    0deg,
    rgba(120, 215, 221, 0) 0%,
    rgba(182, 41, 242, 1) 10%,
    rgba(182, 41, 242, 1) 50%,
    rgba(182, 41, 242, 1) 98%,
    rgba(249, 78, 78, 0) 100%
  );
  --loading-color: #b629f2;
  --font-title: "Pixellari";
  --font-title2: "Riviera";
  --font-subtitle: "Gilroy";
  --font: "Poppins";
}

.white {
  --bg-header-color: rgba(233, 233, 233, 0.6);
  --bg-color: #e9e9e9;
  --bgTwo-color: #ffffff;
  --bgThree-color: #e9e9e9;
  --body-color: #0e100e;
  --body2-color: #303030;
  --bodyInvert-color: #e9e9e9;
  --title-color: #ff0000;
  --subtitle-color: #6e0000;
  --subtitleInvert-color: #0e100e;
  --blueCyan-color: #ff0000;
  --bg-project: #cfc4c4;
  --red-color: #ff0000;
  --cursor-color: rgba(110, 0, 0, 0.5);
  --gradient: linear-gradient(
    0deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --gradientS: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientBI: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    var(--subtitle-color)
  );
  --gradientR: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--red-color));
  --gradientRI: linear-gradient(to top, rgba(0, 0, 0, 0), var(--red-color));
  --gradientLine: linear-gradient(
    0deg,
    rgba(120, 215, 221, 0) 0%,
    rgb(242, 41, 41) 10%,
    rgb(242, 41, 41) 50%,
    rgb(242, 41, 41) 98%,
    rgba(249, 78, 78, 0) 100%
  );
  --loading-color: #0e100e;
  --font-title: "Pixellari";
  --font-title2: "Riviera";
  --font-subtitle: "Gilroy";
  --font: "Poppins";
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  text-align: center;
  font-family: var(--font);
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  background: var(--bgThree-color);
  scroll-behavior: smooth;
  word-break: break-word;
  background-image: url("./Images/Backgrounds/dev.webp");
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
}

.hacker {
  html,
  body {
    background-color: #000000;
    background-image: url("./Images/Backgrounds/hacker.gif");
    background-repeat: repeat !important;
    background-size: contain !important;
  }
}

.cyber {
  html,
  body {
    background-color: #09031f;
    background-image: url("./Images/Backgrounds/cyber.webp");
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: cover;
  }
}

.white {
  html,
  body {
    background-color: var(--bgThree-color);
    background-image: none;
  }
}

body::-webkit-scrollbar {
  width: 12px;
  background: var(--bg-color);
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px var(--subtitle-color);
  background: var(--bg-color);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--subtitle-color);
  border-radius: 20px;
  border: 3px solid var(--bg-color);
}

body::-webkit-scrollbar-thumb:hover {
  background: var(--subtitleInvert-color);
}

main {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: var(--bgThree-color);
}

hr {
  width: 100%;
  max-width: 150px;
  height: 5px;
  background-color: var(--red-color);
  margin: 5px auto 20px auto;

  &:hover {
    background-color: var(--subtitle-color) !important;
  }
}

.main-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 40px;
  font-size: 15px;
  line-height: 48px;
  cursor: pointer;
  font-family: var(--font);
  z-index: 5;
  font-weight: bold;
  color: var(--subtitle-color);
  border: 2px solid transparent;
  background:
    linear-gradient(var(--bg-color), var(--bg-color)) padding-box,
    var(--gradientS) border-box;
  border-radius: 10px;
  text-transform: uppercase;
}

.white {
  .cover-btn {
    color: var(--bg-color);
  }
}

.main-btn:hover {
  color: var(--red-color);
  background:
    linear-gradient(var(--bg-color), var(--bg-color)) padding-box,
    var(--gradientR) border-box;
}

.main-btn-2 {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 40px;
  font-size: 15px;
  line-height: 48px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 0px 1px 8px 0px var(--subtitle-color);
  -moz-box-shadow: 0px 1px 8px 0px var(--subtitle-color);
  box-shadow: 0px 1px 8px 0px var(--subtitle-color);
  background-color: var(--subtitle-color);
  color: var(--bg-color);
  border-color: var(--subtitle-color);
  text-transform: uppercase;
  font-weight: bold;
}

.main-btn-2:hover {
  background-color: var(--bg-color);
  color: var(--subtitle-color);
  border: 1px solid var(--subtitle-color);
}

.cover-btn {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 40px;
  font-size: 15px;
  font-weight: bold;
  line-height: 48px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-family: var(--font);
  -webkit-box-shadow: 0px 1px 8px 0px var(--subtitle-color);
  -moz-box-shadow: 0px 1px 8px 0px var(--subtitle-color);
  box-shadow: 0px 1px 8px 0px var(--subtitle-color);
  background-color: var(--subtitle-color);
  color: var(--bg-color);
  border-color: var(--subtitle-color);
  text-transform: uppercase;
}

.cover-btn:hover {
  background-color: var(--bg-color);
  color: var(--red-color);
  border: 1px solid var(--red-color);
  -webkit-box-shadow: 0px 1px 8px 0px var(--red-color);
  -moz-box-shadow: 0px 1px 8px 0px var(--red-color);
  box-shadow: 0px 1px 8px 0px var(--red-color);
}

.hacker {
  .title {
    animation: glitch 400ms infinite !important;
  }
}

.cyber {
  .title {
    animation: neon 1s ease-in-out infinite alternate;
  }
}

.white {
  .title {
    text-shadow: 0.03em 0.03em 0 rgb(155, 155, 155);
    animation: none;
  }
}

@keyframes piscar {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.title {
  animation: piscar 1.5s linear infinite;
}

@keyframes neon {
  from {
    text-shadow:
      0 0 15px var(--red-color),
      0 0 15px var(--blue-color),
      0 0 15px var(--subtitle-color);
  }
}

.title h2 {
  font-family: var(--font-subtitle);
  font-weight: 900;
}

.section-title {
  font-family: var(--font-title);
}

.title span {
  font-size: 1.7rem;
  color: var(--blueCyan-color);

  &:hover {
    color: var(--subtitle-color);
  }
}

.section-title p {
  font-family: var(--font);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: inherit;
}

button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  padding: 0;
  border: none;
  background-color: unset;
}

input {
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

textarea {
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

select {
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

@media screen and (max-width: 191px) {
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 768px) {
  .section-title .title {
    font-size: 2.3rem !important;
  }

  .title span {
    font-size: 1.5rem !important;
  }
}
